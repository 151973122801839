// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-dimmer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  height: 100vh;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  z-index: 0;
}

.modal {
  z-index: 999;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}

.modal .modal-container {
  position: relative;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 5px -3px,
    rgba(0, 0, 0, 0.07) 0px 8px 10px 1px, rgba(0, 0, 0, 0.05) 0px 3px 14px 2px;
  border-radius: 12px;
  overflow: hidden;
  width: 100%;
  max-width: 580px;
  max-height: unset;
  padding: 48px;
  margin: auto;
  z-index: 1;
}

.modal .modal-container .modal-close {
  position: absolute;
  top: 16px;
  right: 16px;
}

.modal h1 {
  color: #424242;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 32px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/Modal/Modal.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,QAAQ;EACR,SAAS;EACT,OAAO;;EAEP,aAAa;EACb,YAAY;EACZ,0CAA0C;EAC1C,eAAe;EACf,UAAU;AACZ;;AAEA;EACE,YAAY;;EAEZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;;EAEvB,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,SAAS;EACT,OAAO;EACP,aAAa;EACb,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,uBAAuB;EACvB;8EAC4E;EAC5E,mBAAmB;EACnB,gBAAgB;EAChB,WAAW;EACX,gBAAgB;EAChB,iBAAiB;EACjB,aAAa;EACb,YAAY;EACZ,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;AACb;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".modal-dimmer {\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n\n  height: 100vh;\n  width: 100vw;\n  background-color: rgba(255, 255, 255, 0.8);\n  cursor: pointer;\n  z-index: 0;\n}\n\n.modal {\n  z-index: 999;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  height: 100vh;\n  width: 100vw;\n}\n\n.modal .modal-container {\n  position: relative;\n  background-color: white;\n  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 5px -3px,\n    rgba(0, 0, 0, 0.07) 0px 8px 10px 1px, rgba(0, 0, 0, 0.05) 0px 3px 14px 2px;\n  border-radius: 12px;\n  overflow: hidden;\n  width: 100%;\n  max-width: 580px;\n  max-height: unset;\n  padding: 48px;\n  margin: auto;\n  z-index: 1;\n}\n\n.modal .modal-container .modal-close {\n  position: absolute;\n  top: 16px;\n  right: 16px;\n}\n\n.modal h1 {\n  color: #424242;\n  font-size: 22px;\n  font-weight: 500;\n  margin-bottom: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
