// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.color-stripe {
  flex: 1 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.color-stripe .color-stripe-bar {
  flex: 1 1;
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.05);
}
`, "",{"version":3,"sources":["webpack://./src/components/Share/Stripe.css"],"names":[],"mappings":"AAAA;EACE,SAAO;EACP,aAAa;EACb,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,SAAO;EACP,4CAA4C;AAC9C","sourcesContent":[".color-stripe {\n  flex: 1;\n  display: flex;\n  flex-direction: row;\n  align-items: stretch;\n}\n\n.color-stripe .color-stripe-bar {\n  flex: 1;\n  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.05);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
