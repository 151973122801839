// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-page-wrapper {
  padding: 20px;
}

.error-page-content h1 {
  margin-bottom: 0.4em;
}

@media (min-width: 450px) {
  .error-page-wrapper {
    background: #f1f1f1;
    display: flex;
    flex: 1 1;
    align-items: center;
    justify-content: center;
  }

  .error-page-content {
    background: #fff;
    border-radius: 20px;
    padding: 40px;
    max-width: 400px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Share/ErrorPage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE;IACE,mBAAmB;IACnB,aAAa;IACb,SAAO;IACP,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,gBAAgB;IAChB,mBAAmB;IACnB,aAAa;IACb,gBAAgB;EAClB;AACF","sourcesContent":[".error-page-wrapper {\n  padding: 20px;\n}\n\n.error-page-content h1 {\n  margin-bottom: 0.4em;\n}\n\n@media (min-width: 450px) {\n  .error-page-wrapper {\n    background: #f1f1f1;\n    display: flex;\n    flex: 1;\n    align-items: center;\n    justify-content: center;\n  }\n\n  .error-page-content {\n    background: #fff;\n    border-radius: 20px;\n    padding: 40px;\n    max-width: 400px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
