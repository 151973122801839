// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn {
  position: relative;
  font: inherit;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  cursor: pointer;

  padding: 0 25px;
  height: 40px;
  border: none;
  border-radius: 50px;
}

.btn-text {
  font: inherit;
  font-size: inherit;
  font-weight: inherit;
  text-transform: inherit;
  text-decoration: underline;
  color: inherit;
  height: auto;
  padding: unset;
  border-radius: unset;
  background-color: transparent;
}

.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  padding: 0;

  transition: background-color 0.2s ease;
}

.btn-icon:hover,
.btn-icon:focus {
  background-color: rgba(0, 0, 0, 0.1);
}

.btn[disabled] {
  cursor: not-allowed;
}

.btn-loading .btn-loading-loader {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/Button/Button.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,eAAe;EACf,gBAAgB;EAChB,yBAAyB;EACzB,qBAAqB;EACrB,eAAe;;EAEf,eAAe;EACf,YAAY;EACZ,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,oBAAoB;EACpB,uBAAuB;EACvB,0BAA0B;EAC1B,cAAc;EACd,YAAY;EACZ,cAAc;EACd,oBAAoB;EACpB,6BAA6B;AAC/B;;AAEA;EACE,oBAAoB;EACpB,mBAAmB;EACnB,uBAAuB;;EAEvB,6BAA6B;EAC7B,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,UAAU;;EAEV,sCAAsC;AACxC;;AAEA;;EAEE,oCAAoC;AACtC;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,OAAO;EACP,MAAM;EACN,QAAQ;EACR,SAAS;EACT,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":[".btn {\n  position: relative;\n  font: inherit;\n  font-size: 14px;\n  font-weight: 600;\n  text-transform: uppercase;\n  letter-spacing: 0.5px;\n  cursor: pointer;\n\n  padding: 0 25px;\n  height: 40px;\n  border: none;\n  border-radius: 50px;\n}\n\n.btn-text {\n  font: inherit;\n  font-size: inherit;\n  font-weight: inherit;\n  text-transform: inherit;\n  text-decoration: underline;\n  color: inherit;\n  height: auto;\n  padding: unset;\n  border-radius: unset;\n  background-color: transparent;\n}\n\n.btn-icon {\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n\n  background-color: transparent;\n  border-radius: 50%;\n  height: 32px;\n  width: 32px;\n  padding: 0;\n\n  transition: background-color 0.2s ease;\n}\n\n.btn-icon:hover,\n.btn-icon:focus {\n  background-color: rgba(0, 0, 0, 0.1);\n}\n\n.btn[disabled] {\n  cursor: not-allowed;\n}\n\n.btn-loading .btn-loading-loader {\n  position: absolute;\n  left: 0;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  overflow: hidden;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
