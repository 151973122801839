// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes slide {
  from {
    transform: translate(0, 200px);
  }

  to {
    transform: translate(0);
  }
}

.install-prompt {
  position: absolute;
  bottom: 0;
  background: #fff;
  box-shadow: 0 0px 16px rgba(0, 0, 0, 0.2);
  color: #000;
  left: 0;
  right: 0;
  animation: slide 0.3s 1;
  padding: 20px;
}

.install-prompt-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.install-prompt-icon {
  margin-right: 10px;
  border-radius: 8px;
}

.install-prompt-button {
  display: inline-block;
  background: #06f;
  padding: 10px 20px;
  font-weight: 600;
  color: #fff;
  font-size: 14px;
  border-radius: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/components/InstallPrompt.css"],"names":[],"mappings":"AAAA;EACE;IACE,8BAA8B;EAChC;;EAEA;IACE,uBAAuB;EACzB;AACF;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,gBAAgB;EAChB,yCAAyC;EACzC,WAAW;EACX,OAAO;EACP,QAAQ;EACR,uBAAuB;EACvB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;EAChB,WAAW;EACX,eAAe;EACf,kBAAkB;AACpB","sourcesContent":["@keyframes slide {\n  from {\n    transform: translate(0, 200px);\n  }\n\n  to {\n    transform: translate(0);\n  }\n}\n\n.install-prompt {\n  position: absolute;\n  bottom: 0;\n  background: #fff;\n  box-shadow: 0 0px 16px rgba(0, 0, 0, 0.2);\n  color: #000;\n  left: 0;\n  right: 0;\n  animation: slide 0.3s 1;\n  padding: 20px;\n}\n\n.install-prompt-header {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  margin-bottom: 20px;\n}\n\n.install-prompt-icon {\n  margin-right: 10px;\n  border-radius: 8px;\n}\n\n.install-prompt-button {\n  display: inline-block;\n  background: #06f;\n  padding: 10px 20px;\n  font-weight: 600;\n  color: #fff;\n  font-size: 14px;\n  border-radius: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
