// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*::-webkit-scrollbar {
  display: none;
}

.status-bar-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.status-bar-wrapper--responsive {
  position: absolute;
}
`, "",{"version":3,"sources":["webpack://./src/components/Renderer.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;AACV;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":["*::-webkit-scrollbar {\n  display: none;\n}\n\n.status-bar-wrapper {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n}\n\n.status-bar-wrapper--responsive {\n  position: absolute;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
