// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.multi-menu {
  background: #fff;
}

.multi-menu-wrapper-attached > .multi-menu-inner-wrapper:before {
  background: #fff;
}

.multi-menu-item-label {
  padding-left: 16px;
}

.wrapped-select.white .multi-menu {
  background: #fff;
}

.wrapped-select.white .multi-select-menu-selection {
  font-size: 14px;
  font-weight: 500;
  background: #fff;
  padding-left: 16px;
}

.wrapped-select.white:not(.expanded) .multi-select-menu-selection {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
}

.wrapped-select.white.expanded .multi-select-menu-selection {
  box-shadow: none;
}

.multi-select-menu .icon {
  margin: -8px 4px -8px -2px;
  background-color: #9a9a9a;
}

.multi-select-menu .disabled .icon {
  opacity: 0.5;
}

.multi-select-menu .multi-menu-item.open .icon,
.multi-select-menu .multi-menu-item:hover .icon {
  background-color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/components/Share/WrappedSelect.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,wCAAwC;EACxC,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,0BAA0B;EAC1B,yBAAyB;AAC3B;;AAEA;EACE,YAAY;AACd;;AAEA;;EAEE,sBAAsB;AACxB","sourcesContent":[".multi-menu {\n  background: #fff;\n}\n\n.multi-menu-wrapper-attached > .multi-menu-inner-wrapper:before {\n  background: #fff;\n}\n\n.multi-menu-item-label {\n  padding-left: 16px;\n}\n\n.wrapped-select.white .multi-menu {\n  background: #fff;\n}\n\n.wrapped-select.white .multi-select-menu-selection {\n  font-size: 14px;\n  font-weight: 500;\n  background: #fff;\n  padding-left: 16px;\n}\n\n.wrapped-select.white:not(.expanded) .multi-select-menu-selection {\n  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);\n  border-radius: 50px;\n}\n\n.wrapped-select.white.expanded .multi-select-menu-selection {\n  box-shadow: none;\n}\n\n.multi-select-menu .icon {\n  margin: -8px 4px -8px -2px;\n  background-color: #9a9a9a;\n}\n\n.multi-select-menu .disabled .icon {\n  opacity: 0.5;\n}\n\n.multi-select-menu .multi-menu-item.open .icon,\n.multi-select-menu .multi-menu-item:hover .icon {\n  background-color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
