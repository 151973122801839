// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./icons/send.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./icons/close.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./icons/exposure-plus-1.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon {
  display: inline-block;
  height: 24px;
  width: 24px;
  mask-type: alpha;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  background: #333;
}

.icon-small {
  height: 20px;
  width: 20px;
}

.icon-placeholder {
  background: transparent;
  mask: none;
}

/* DEFINE ICONS */
.icon-send {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

.icon-close {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

.icon-exposure-plus-1 {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/Icon/Icons.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,YAAY;EACZ,WAAW;EACX,gBAAgB;EAChB,sBAAsB;EACtB,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,uBAAuB;EACvB,UAAU;AACZ;;AAEA,iBAAiB;AACjB;EACE,mDAAmC;AACrC;;AAEA;EACE,mDAAoC;AACtC;;AAEA;EACE,mDAA8C;AAChD","sourcesContent":[".icon {\n  display: inline-block;\n  height: 24px;\n  width: 24px;\n  mask-type: alpha;\n  mask-repeat: no-repeat;\n  mask-position: center;\n  mask-size: contain;\n  background: #333;\n}\n\n.icon-small {\n  height: 20px;\n  width: 20px;\n}\n\n.icon-placeholder {\n  background: transparent;\n  mask: none;\n}\n\n/* DEFINE ICONS */\n.icon-send {\n  mask-image: url('./icons/send.svg');\n}\n\n.icon-close {\n  mask-image: url('./icons/close.svg');\n}\n\n.icon-exposure-plus-1 {\n  mask-image: url('./icons/exposure-plus-1.svg');\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
