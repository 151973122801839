// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-wrapper > label {
  display: block;
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 8px 0;
}

.input-wrapper .input-group {
  position: relative;

  display: flex;
  align-items: flex-start;
  justify-content: stretch;

  background-color: white;
  box-shadow: inset 0 0 0 1px #ccc;
  border-radius: 6px;
  height: 44px;

  overflow: hidden;
}

.input-wrapper .input-group input {
  flex: 1 1;
  height: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 8px;

  font-size: 16px;
  font-weight: 500;
}

.input-wrapper .input-group .input-group-prepend,
.input-wrapper .input-group .input-group-append {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  height: 100%;
}

.input-wrapper .input-group .input-group-prepend > *,
.input-wrapper .input-group .input-group-append > * {
  border-radius: unset;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/Input/Input.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;;EAElB,aAAa;EACb,uBAAuB;EACvB,wBAAwB;;EAExB,uBAAuB;EACvB,gCAAgC;EAChC,kBAAkB;EAClB,YAAY;;EAEZ,gBAAgB;AAClB;;AAEA;EACE,SAAO;EACP,YAAY;EACZ,6BAA6B;EAC7B,YAAY;EACZ,aAAa;EACb,YAAY;;EAEZ,eAAe;EACf,gBAAgB;AAClB;;AAEA;;EAEE,oBAAoB;EACpB,mBAAmB;EACnB,uBAAuB;;EAEvB,YAAY;AACd;;AAEA;;EAEE,oBAAoB;AACtB","sourcesContent":[".input-wrapper > label {\n  display: block;\n  font-size: 14px;\n  font-weight: 500;\n  margin: 0 0 8px 0;\n}\n\n.input-wrapper .input-group {\n  position: relative;\n\n  display: flex;\n  align-items: flex-start;\n  justify-content: stretch;\n\n  background-color: white;\n  box-shadow: inset 0 0 0 1px #ccc;\n  border-radius: 6px;\n  height: 44px;\n\n  overflow: hidden;\n}\n\n.input-wrapper .input-group input {\n  flex: 1;\n  height: 100%;\n  background-color: transparent;\n  border: none;\n  outline: none;\n  padding: 8px;\n\n  font-size: 16px;\n  font-weight: 500;\n}\n\n.input-wrapper .input-group .input-group-prepend,\n.input-wrapper .input-group .input-group-append {\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n\n  height: 100%;\n}\n\n.input-wrapper .input-group .input-group-prepend > *,\n.input-wrapper .input-group .input-group-append > * {\n  border-radius: unset;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
