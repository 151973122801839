import React from 'react'

const development = process.env['NODE_ENV'] === 'development'

// To start the development server with WhyDidYouRender enabled, run: yarn start:wdyr
const useWdyr = process.env['REACT_APP_WHY_DID_YOU_RENDER'] === 'true'

// via: https://github.com/welldone-software/why-did-you-render
if (development && useWdyr) {
  // eslint-disable-next-line
  const whyDidYouRender = require('@welldone-software/why-did-you-render')
  // eslint-disable-next-line
  whyDidYouRender(React, { trackAllPureComponents: true })
}
