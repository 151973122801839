// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root,
.app {
  height: 100%;
}

body {
  font-family: -apple-system, "Segoe UI", Roboto, Ubuntu, "SF Pro Text", system-ui, "Helvetica Neue", sans-serif;
  overflow: hidden;
  background: #fff;
  -webkit-user-select: none;
          user-select: none;
  -webkit-font-smoothing: antialiased;
}

.app {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.standalone {
  height: 100vh;
}

.app-border {
  position: fixed;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border: 1px solid #000;
  z-index: 10000;
  pointer-events: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/App.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;;AAEA;;;;EAIE,YAAY;AACd;;AAEA;EACE,8GAA8G;EAC9G,gBAAgB;EAChB,gBAAgB;EAChB,yBAAiB;UAAjB,iBAAiB;EACjB,mCAAmC;AACrC;;AAEA;EACE,aAAa;EACb,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;AACX;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,eAAe;EACf,SAAS;EACT,UAAU;EACV,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,cAAc;EACd,oBAAoB;AACtB","sourcesContent":["* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\nhtml,\nbody,\n#root,\n.app {\n  height: 100%;\n}\n\nbody {\n  font-family: -apple-system, \"Segoe UI\", Roboto, Ubuntu, \"SF Pro Text\", system-ui, \"Helvetica Neue\", sans-serif;\n  overflow: hidden;\n  background: #fff;\n  user-select: none;\n  -webkit-font-smoothing: antialiased;\n}\n\n.app {\n  display: flex;\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n}\n\n.standalone {\n  height: 100vh;\n}\n\n.app-border {\n  position: fixed;\n  top: -1px;\n  left: -1px;\n  right: -1px;\n  bottom: -1px;\n  border: 1px solid #000;\n  z-index: 10000;\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
